import { useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { useRecoilState } from "recoil";
import { Dropdown, Icon, Image, Menu, Sidebar } from "semantic-ui-react";
import { authState } from "../atoms/auth";
import "../styles/dashboard_layout.min.css";
import SidebarMenu from "./sidebarMenus/SidebarMenu";
import Logo from "../assets/images/chatbot.png";
import { removeUserFromStorage } from "../services/authService";

const DashboardLayout = () => {
  const [authValue, setAuthValue] = useRecoilState(authState);

  const [visible, setVisible] = useState(false);
  const handleLogout = () => {
    removeUserFromStorage();
    setAuthValue(null);
  };
  return (
    <div className="DashboardLayout">
      <Sidebar
        as={Menu}
        animation="overlay"
        onHide={() => setVisible(false)}
        vertical
        visible={visible}
      >
        {<SidebarMenu />}
      </Sidebar>
      <div className="pusher">
        <div className="full height">
          <div className="toc">
            <Menu fluid borderless vertical>
              {<SidebarMenu />}
            </Menu>
          </div>
          <div className="page-content min-h-[100vh]">
            <Menu fixed="top" secondary>
              <Menu.Item className="brand" as={Link} to="/">
                <Image
                  src={Logo}
                  className={"ui centered logo"}
                  size="mini"
                  alt="Logo imge"
                />
                <span style={{ marginLeft: "5px" }}>EduAlly</span>
              </Menu.Item>
              <Menu.Menu className="right desktop only">
                <Dropdown item text={`${authValue?.user?.first_name}`}>
                  <Dropdown.Menu>
                    {/* <Dropdown.Item>Electronics</Dropdown.Item>
                    <Dropdown.Item>Automotive</Dropdown.Item>
                    <Dropdown.Item>Home</Dropdown.Item> */}
                  </Dropdown.Menu>
                </Dropdown>

                <Menu.Item className="log-out" as="a" onClick={handleLogout}>
                  <Icon name="log out" />
                  Déconnexion
                </Menu.Item>
              </Menu.Menu>
              <div className="right menu mobile only">
                <Menu.Item as="a" onClick={() => setVisible(!visible)}>
                  <Icon name="bars" />
                </Menu.Item>
              </div>
            </Menu>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
