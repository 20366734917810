import React from "react";
import { authState } from "../../atoms/auth";
import { useRecoilState } from "recoil";
import AdminMenus from "./AdminMenus";
import { USER_GROUPS } from "../../constants";
import UserMenu from "./UserMenus";

function SidebarMenu() {
  const [authValue, setAuthValue] = useRecoilState(authState);

  const getUserMenus = () => {
    if (authValue?.user?.user_role === USER_GROUPS.ADMIN) {
      return <AdminMenus />;
    }
    if (authValue?.user?.user_role === USER_GROUPS.USER) {
      return <UserMenu />;
    }
  };

  return getUserMenus();
}

export default SidebarMenu;
