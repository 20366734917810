import React from "react";
import { Navigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { authState } from "../atoms/auth";
import { USER_GROUPS } from "../constants";
import {
  getUserFromStorage,
  removeUserFromStorage,
} from "../services/authService";

function ProtectedRoute({ exept, children }) {
  const authStore = useRecoilValue(authState);

  // Vérifie si l'utilisateur est authentifié en vérifiant si le token est présent
  const isAuthenticated = !!authStore?.token || !!getUserFromStorage();

  // Si l'utilisateur n'est pas authentifié, redirige vers la page de connexion
  if (
    !isAuthenticated ||
    !(
      exept?.includes(authStore?.user?.user_role) ||
      exept?.includes(getUserFromStorage()?.user?.user_role)
    )
  ) {
    removeUserFromStorage();
    return <Navigate to="/connexion" replace />;
  }

  // Si l'utilisateur est authentifié, affiche les enfants (c'est-à-dire les routes protégées)
  return <>{children}</>;
}

export default ProtectedRoute;
