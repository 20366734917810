export const USER_STORAGE = "PazFUWta0QZg1Mv5cHpifUzw9RLNOdwq";
export const USER_GROUPS = {
  ADMIN: "ADMIN",
  USER: "USER",
};
export const GENDER_LIST = {
  HOMME: "HOMME",
  FEMME: "FEMME",
  AUTRE: "AUTRE",
};
