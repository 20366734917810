import dayjs from "dayjs";
import React, { useState, useEffect } from "react";
import {
  Breadcrumb,
  Divider,
  Dropdown,
  Grid,
  GridRow,
  Header,
  Icon,
  Input,
  Label,
  Pagination,
  Table,
} from "semantic-ui-react";
import Page from "../../components/Page";
import * as usersService from "../../services/usersService";

function UsersPage() {
  const [users, setUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState();
  const [selectedUserId, setSelectedUserId] = useState();

  const fetchUsers = async () => {
    try {
      const response = await usersService.fetchAll(
        `page=${activePage}&search=${search}`
      );
      if (response?.status === 200 && typeof response.data === "object") {
        const { pagination, data } = response.data;
        setUsers(data);
        setPagination(pagination);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchUsers();

    return () => {};
  }, [search, activePage]);

  const handleOpenEdit = (userId) => {
    setSelectedUserId(userId);
    setOpen(true);
  };

  useEffect(() => {
    if (open === false) {
      setSelectedUserId(null);
    }
    return () => {};
  }, [open]);

  return (
    <Page title="Utilisateurs">
     <Breadcrumb>
        <Breadcrumb.Section link>Admin</Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section active>Utilisateurs</Breadcrumb.Section>
      </Breadcrumb>
      <Divider />
      <GridRow className="Compagnies">
        <Grid stackable>
          <Grid.Column
            width={8}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Header>Liste des Utilisateurs [{pagination?.total || 0}]</Header>
          </Grid.Column>
          <Grid.Column
            width={8}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Input
              placeholder="Rechercher..."
              className="search"
              value={search}
              onChange={(e, data) => setSearch(data.value)}
            >
              <input name="search" />
            </Input>
            {/*  <Button
              style={{ marginLeft: "10px" }}
              circular
              icon={<Icon name="add" />}
              onClick={() => setOpen(true)}
            /> */}
          </Grid.Column>
        </Grid>
        <>
          <Table striped >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell>Nom</Table.HeaderCell>
                <Table.HeaderCell>Prénom</Table.HeaderCell>
               
                <Table.HeaderCell>E-mail</Table.HeaderCell>
                <Table.HeaderCell>Phone</Table.HeaderCell>
                <Table.HeaderCell>Groupe</Table.HeaderCell>
                <Table.HeaderCell>Pays</Table.HeaderCell>
                <Table.HeaderCell>Date Joined</Table.HeaderCell>
                <Table.HeaderCell>Actions </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {users?.map((user, index) => (
                <Table.Row key={`${user.idusers}`}>
                  <Table.Cell>{index + 1}</Table.Cell>
                  <Table.Cell>{user.last_name}</Table.Cell>
                  <Table.Cell>{user.first_name}</Table.Cell>                  
                  <Table.Cell>
                    {user.email}
                    <Label
                    style={{ marginLeft: "5px" }}
                      circular
                      empty
                      color={user?.banned === 0 ? "green" : "red"}
                    />
                  </Table.Cell>
                  <Table.Cell>{user.phone}</Table.Cell>
                  <Table.Cell>{user.user_role}</Table.Cell>
                  <Table.Cell>{user.country?.iso3}</Table.Cell>
                  <Table.Cell>
                    {dayjs(user.created_at).format("DD-MM-YYYY")}
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    <Dropdown pointing="right" icon="ellipsis vertical">
                      <Dropdown.Menu>
                        <Dropdown.Item icon="eye" content="Détails" />
                        <Dropdown.Item icon="edit" content="Editer" />
                        <Dropdown.Item icon="trash" content="Supprimer" />
                      </Dropdown.Menu>
                    </Dropdown>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan="9">
                  <Pagination
                    floated="right"
                    defaultActivePage={activePage}
                    ellipsisItem={{
                      content: <Icon name="ellipsis horizontal" />,
                      icon: true,
                    }}
                    firstItem={{
                      content: <Icon name="angle double left" />,
                      icon: true,
                    }}
                    lastItem={{
                      content: <Icon name="angle double right" />,
                      icon: true,
                    }}
                    prevItem={{
                      content: <Icon name="angle left" />,
                      icon: true,
                    }}
                    nextItem={{
                      content: <Icon name="angle right" />,
                      icon: true,
                    }}
                    totalPages={pagination?.pageCount}
                    onPageChange={(e, { activePage }) => {
                      setActivePage(activePage);
                    }}
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </>
      </GridRow>
     {/*  <UserFormModal
        open={open}
        setOpen={setOpen}
        successCallback={fetchUsers}
        selectedUserId={selectedUserId}
      /> */}
    </Page>
  );
}

export default UsersPage;
