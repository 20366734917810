import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { Icon, Menu } from "semantic-ui-react";
import { authState } from "../../atoms/auth";
import { removeUserFromStorage } from "../../services/authService";

function UserMenu() {
  const [authValue, setAuthValue] = useRecoilState(authState);

  return (
    <React.Fragment>
      <Menu.Item as={Link} to="/users/dashboard">
        <span>
          <Icon name="dashboard" /> Dashboard
        </span>
      </Menu.Item>
      <Menu.Item as={Link} to="/chat">
        <span>
          <Icon name="chat" /> Chat
        </span>
      </Menu.Item>
      <Menu.Item
        as="a"
        onClick={() => {
          removeUserFromStorage();
          setAuthValue(null);
        }}
      >
        <span>
          <Icon name="log out" color="red" /> Déconnexion
        </span>
      </Menu.Item>
    </React.Fragment>
  );
}

export default UserMenu;
