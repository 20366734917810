import {
  Grid,
  GridRow,
  Header,
} from "semantic-ui-react";

import { Helmet } from "react-helmet";

const PoliciesPage = () => {
  return (
    <GridRow className="PoliciesPage" style={{ padding: 15 }}>
      <Helmet>
        <title>Politique de confidentialité</title>
      </Helmet>
      <Grid container>
        <Grid.Column>
          <Header>Condtions d'utilisation</Header>
          <p>
            En utilisant l'application, vous acceptez ces conditions
            d'utilisation. Nous nous réservons le droit de modifier ces
            conditions à tout moment.
            <br />
            <br />
            EduAlly est une application destinée aux étudiants et élèves.
            <br />
            <br />
            L'utilisation de l'application est réservée à un usage personnel et
            non commercial.
            <br />
            <br />
            Vous êtes seul responsable de tout contenu que vous publiez ou
            transmettrez, et de l'utilisation que vous faites de l'application.
            <br />
            <br />
            Tous les droits d'auteur et de propriété intellectuelle sur
            l'application sont réservés.
            <br />
            <br />
            Nous ne garantissons pas que l'application sera toujours disponible
            ou exempte d'erreur, et déclinons toute responsabilité pour tout
            dommage lié à son utilisation.
            <br />
            <br /> L'utilisation de l'application est soumise à notre politique
            de confidentialité, disponible sur notre site web.
          </p>
          <Header>Politique de confidentialité</Header>
          <p>
          Nous protégeons vos données personnelles et mettons en œuvre des
            mesures de sécurité pour garantir leur sécurité.
           <br/><br/>
            Nous collectons votre nom, votre adresse e-mail et votre mot de
            passe lorsque vous vous inscrivez, et des données de navigation
            lorsque vous utilisez l'application.<br/><br/>
            Nous utilisons vos données personnelles pour vous fournir l'accès à
            l'application et les services que vous avez demandés, et pour vous
            envoyer des notifications, des mises à jour ou des offres spéciales.
           <br/><br/> Nous ne partageons pas vos données personnelles avec des
            tiers sans votre consentement, sauf dans certains cas précis.<br/><br/>
            Nous prenons des mesures de sécurité pour protéger vos données
            personnelles contre l'accès non autorisé, la modification, la
            divulgation ou la suppression. Nous vous encourageons à protéger
            votre mot de passe et à ne pas partager vos informations de compte.
          </p>
        </Grid.Column>
      </Grid>
    </GridRow>
  );
};

export default PoliciesPage;
